@import 'global.scss';

.modalContainer {
  @include tablet {
    display: flex;
  }
}

.modal > div {
  display: flex;
  align-items: flex-end;
  z-index: $z-index-cookies;
  padding-bottom: calc(70px + 20px); // высота мобильного меню + 20px

  @include desktop-max {
    padding-bottom: 5dvh;
  }
}

.tariffMobilePage.modal > div {
  padding-bottom: calc(116px + 20px); // высота корзины в МС + 20px

  @include desktop-max {
    padding-bottom: 5dvh;
  }
}

.tariffHomeInternetPage.modal > div {
  padding-bottom: calc(72px + 20px); // высота корзины в ДИ + 20px

  @include desktop-max {
    padding-bottom: 5dvh;
  }
}

.section {
  display: flex;

  @include only-mobile {
    .arrow {
      display: block;
    }
  }
  @include tablet {
    .arrow {
      display: none;
    }
  }
}

.content {
  box-shadow: 0 15px 45px 15px #3456fb33;
}

.title {
  @include label-l4;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: $color-primary-900;
}

.arrow {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.subTitle {
  @include label-l4;
  letter-spacing: -0.01em;
}

.btnWrapper {
  display: flex;
  justify-content: space-around;
  gap: 16px;
  margin-top: 24px;

  @include tablet {
    justify-content: center;
    padding-left: 24px;
    flex-direction: column;
    gap: 16px;
  }
}

.btn {
  @include label-l4;
  border-radius: 12px;
  width: 139px;
  height: 40px;
  background-color: $color-primary-500;
  border: none;
  outline: none;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: $color-primary-0;
  cursor: pointer;
}

.btnMore {
  @include label-l4;
  border-radius: 12px;
  width: 139px;
  height: 40px;
  background-color: $color-base-100;
  border: none;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: $color-primary-500;
  cursor: pointer;
}

.link {
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
}

// details modal
.detailsModal > div {
  z-index: $z-index-cookies-details;
}

.details {
  max-height: 80vh;
  overflow-y: auto;

  .title {
    @include heading-h3;
    color: $color-primary-900;
    margin-bottom: 12px;
    @include only-mobile {
      width: 232px;
    }
  }

  .subTitle {
    @include label-l4;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  .answer {
    padding-top: 12px;
  }
  .accordion {
    &__item {
      border: 1px solid $color-base-300;
      padding: 0;
      margin: 0;
    }

    &__control {
      height: 65px;
      @include label-l3;

      &[data-active] {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      width: 100%;
      background-color: $color-primary-100;
      color: $color-black;
      word-wrap: break-word;
      border: none;

      &[data-active] {
        background-color: $color-primary-100;
      }

      &[data-active]:hover {
        background-color: $color-primary-100;
      }
    }

    &__chevron {
      color: $color-shevron-grey;
    }

    &__content {
      @include label-l6;
      padding: 16px 20px 16px 20px;
    }
  }

  .question {
    @include label-l3;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  .answer {
    @include label-l4;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}
