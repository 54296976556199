@import 'global.scss';

.body {
  display: flex;
  align-items: center;
  gap: 12px;
}

.label {
  @include label-l4;
  padding: 0;
  cursor: pointer;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}

.description {
  @include short-text-t6;
  color: $color-base-500;
  margin: 0;
  padding: 0;
}

.radio {
  width: 24px;
  height: 24px;
  border-color: $color-base-400;
  cursor: pointer;

  &:checked {
    background-color: $color-primary-500;
    border-color: $color-primary-500;
  }

  &__inner {
    align-self: unset;
  }
}

.icon {
  transform: scale(1.1) !important;
}

.error {
  @include label-l5;
  color: $color-error-fire;
  padding: 0;
  margin: 0;
  margin-top: -4px;
}

.group {
  &__root {
    display: flex;
    flex-direction: column;
    gap: $gap-mobile;
  }
}
