@import 'global.scss';

.description {
  @include paragraph-p4;
  color: $color-base-900;
  span {
    text-wrap: nowrap;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: $gap-mobile;
  margin-bottom: $corner-radius-large;
}
.input {
  height: 60px;
  width: 42px;
  @include heading-h2;
  color: $color-base-900;
  border-radius: $other-input;
  border-color: $color-base-200;

  &:focus {
    border-color: $color-base-400;
  }

  &[aria-invalid='true'] {
    color: $color-base-900;
    border-color: $color-error-fire;
  }

  &__root {
    gap: 8px;
  }
  &__error {
    color: $color-error-fire;
    @include label-l5;
    text-align: center;
  }
}
.button {
  color: $color-primary-500;
  padding: 13px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include label-l4-m;
  margin-top: 12px;
  cursor: pointer;

  &:hover,
  &:active {
    color: $color-primary-600;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &__title {
    color: $color-base-0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.16px;
  }
  &__text {
    margin-top: 20px;
    color: $color-base-0;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  &__button {
    color: $color-primary-200;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.16px;
    margin-top: 4px;
    cursor: pointer;
  }
}

.timer {
  color: $color-base-500;
  @include label-l4-m;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    color: $color-base-500;
    cursor: unset;
  }
}
