@import 'global.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  @include heading-h5;
  color: $color-neutral-gray;

  &__disabled {
    @include label-l4; 
  }

  &__time {
    margin: 24px 0;
  }
}
.subtitle {
  @include short-text-t6;
  color: $color-base-500;
}
.selected {
  @include label-l4;
}
