@import 'global.scss';

.calendar {
  border: none;
  margin: 0;
  width: 100%;
  max-width: 335px;

  :global(.react-datepicker__navigation) {
    top: 12px;
  }
  :global(.react-datepicker__navigation-icon::before) {
    border-width: 2px 2px 0 0;
    border-color: $color-base-700;
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
    border: none;
    padding: 11px 0 6px;
  }

  :global(.react-datepicker__month-container) {
    float: unset;
  }

  :global(.react-datepicker__current-month) {
    @include label-l4-m;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  :global(.react-datepicker__day-names) {
    margin: 0;
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: center;
    height: 28px;
  }

  :global(.react-datepicker__day-name) {
    @include label-l4;
    color: $color-base-700;
    margin: 0;
    width: auto;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  :global(.react-datepicker__month) {
    margin: 0;
  }

  :global(.react-datepicker__week) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: center;
    height: 36px;
  }

  :global(.react-datepicker__day) {
    margin: 0;
    @include label-l4;
    padding: 10px;
    width: auto;
    color: $color-base-900;
    border-radius: 12px;

    &:hover {
      background-color: $color-base-100;
    }
  }

  :global(.react-datepicker__day--weekend) {
    color: $color-error-fire;
  }

  :global(.react-datepicker__day--selected) {
    color: $color-base-0;
    background-color: $color-primary-500 !important;
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: inherit;
  }

  :global(.react-datepicker__day--disabled) {
    color: $color-base-400;
    pointer-events: none;
  }
}
