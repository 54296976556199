@import 'global.scss';

.wrapper {
  min-height: 204px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    'app'
    'faq'
    'problems'
    'social';
  z-index: 5;

  @include tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'faq problems'
      'app social';
    gap: 20px;
    align-items: center;
  }

  @include desktop {
    margin: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 'faq problems app social';
    gap: 0px;
  }

  &__faqPage {
    @include tablet {
      grid-template-areas: 'app social';
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'app app social';
      animation: show 0.8s ease-in-out;
    }
  }

  &__mobApp {
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'faq problems'
        'social social';
      gap: 20px;
      align-items: center;
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'faq problems social';
      gap: 0;
    }
  }
}


@keyframes show {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.faq {
  grid-area: faq;
}

.problemsBlock {
  grid-area: problems;
}

.mobileAppBlock {
  grid-area: app;
}

.socialBlock {
  margin: 20px auto 0;
  grid-area: social;

  @include tablet {
    margin-top: 0;
    margin-left: calc(100% - 50%);
    width: 96px;
  }

  @include desktop {
    margin-left: 160px;
  }

  &__faqPage {
    flex-wrap: nowrap;

    @include desktop {
      margin-left: 180px;
    }
  }
}

.resetHeight {
  min-height: 0;
}
