@import 'global.scss';

.container {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  height: 34px;
  align-items: center;
}

.item {
  padding: 10px 16px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $corner-small;

  &__active {
    background-color: $color-primary-500;
  }
  &__disabled {
    background-color: transparent;
  }
}

.link {
  @include label-l4;
  text-wrap: nowrap;

  &__active {
    color: $color-base-100;
  }
  &__disabled {
    color: $color-base-400;
    pointer-events: none;
  }
}

.scroll {
  @include scrollbar-horizontal;
}
