@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $gap-mobile;
  width: 100%;
  margin: $gap-mobile 0;

  @include tablet {
    gap: 24px;
    margin: 24px 0 $gap-tablet;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $gap-mobile;

  @include tablet {
    gap: $gap-tablet;
  }
}