@import 'global.scss';

.text {
  @include short-text-t6;
  color: $color-black;
  text-align: center;
  flex: 1;
}

.link {
  color: $color-link;
}
