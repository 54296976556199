@import 'global.scss';

.root {
  .body {
    cursor: pointer !important;
    height: 24px;
    width: 50px;
    // width: 50px;
    // height: 24px;
  }
  .input {
    &:checked {
      & + .track {
        background-color: $color-magenta-100;
        border-color: $color-magenta-100;
        opacity: 1;
      }
      & + * > .thumb {
        left: calc(100% - 18px - 4px);
        opacity: 1;
      }
    }
  }
  .track {
    background-color: rgba(226, 231, 243, 0.6);
    border: none;
    height: 24px;
    width: 50px;
    cursor: pointer !important;
  }

  .thumb {
    background-color: $color-base-0;
    box-shadow:
      0 3px 1px 0 rgba(0, 0, 0, 0.06),
      0 3px 8px 0 rgba(0, 0, 0, 0.15);
    width: 18px;
    height: 18px;
    left: 4px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    opacity: 0.6;
  }

  .trackLabel {
    width: 50px;
    height: 24px;
  }
}
