@import 'global.scss';

.wrapper {
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  padding-top: 10px;
}

.logoIcon {
  padding-bottom: 10px;
}

.iconActiveColor {
  color: $color-primary-500;
}

.innerLabelActive {
  color: $color-primary-500;
}

.opened {
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: $color-base-0;
  transition: 0.2s ease-in-out;
  border-radius: 0 0 30px 0;
  overflow: hidden;
  box-shadow: 25px 25px 40px 0px rgba(26, 2, 186, 0.15);
}

.bottomSheetStyles {
  [data-rsbs-overlay] {
    height: 550px;
    z-index: 2 !important;
  }
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: $z-index-menu-bottom-sheet;
    height: 618px;
  }
}

.openBackground {
  top: 0;
  bottom: 0;
  z-index: 200;
  position: absolute;
}

.closedBackground {
  height: auto;
}

.controlBlock {
  display: flex;
  align-items: center;
  width: 100%;
}

.categoryWrapper {
  display: flex;
  height: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.contentVisible {
  border-top: 1px solid rgba(0, 0, 0, 0.39);
}

.hoverIcon:hover {
  background-color: $color-light-grey;
}

.mobile {
  display: flex;
  width: calc(100% + 2px);
  position: fixed;
  bottom: -1px;
  left: -1px;
  z-index: 9999;
  background-color: #f6f1f1;
  margin: 0 -1px -1px;
}

.mobileIconsWrapper {
  width: 100%;
  background-color: $color-base-0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 190, 0.15);
  display: flex;
  justify-content: space-between;
}

.mobileSearch {
  margin-top: 20px;
}
