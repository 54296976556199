@import 'global.scss';

.mobile_link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: 28px;
  @include heading-h6;
  color: $color-base-700;
}

.link {
  @include label-l5;
  color: $color-base-500;
  height: 12px;

  &__last {
    color: $color-base-900;
  }
}

.breadcrumbs {
  &__root {
    height: 12px;
  }
  &__separator {
    margin: 0 8px;
    height: 12px;
  }
}
