@import 'global.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px 14px 24px;
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  user-select: none;

  @include tablet {
    height: 684px;
    min-height: 684px;
    padding: 52px 24px 24px;
  }

  p {
    margin: 0;
    @include paragraph-p5;
    color: $color-base-700;
  }

  h3 {
    @include heading-h5;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      cursor: pointer;
    }
  }

  span {
    @include label-l4;
  }
}

.bottomsheet {
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.header {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    @include label-l4;
    color: $color-base-700;
  }
}

.title {
  padding: 20px;
  @include heading-h5;
  border-radius: $corner-radius-small;
  background-color: $color-base-200;
  text-align: center;

  @include tablet {
    @include heading-h4;
  }
}

.content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: $gap-horizontal;
}

.emergenceContent {
  opacity: 0;
  animation: emergence 0.5s forwards;
}

@keyframes emergence {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $corner-radius-small;

  &__col {
    width: 100%;
  }

  &__withOr {
    display: flex;
    flex-direction: column;
    gap: $corner-radius-small;

    @include desktop {
      flex-direction: row;
      align-items: center;
    }
  }

  &__select {
    min-height: 340px;
  }

  &__hasMap {
    min-height: fit-content;
  }
}

.col {
  display: flex;
  flex-direction: column;

  &__withAccordion {
    max-height: 400px;
    @include tablet {
      max-height: 240px;
    }
  }
}

.row {
  @include desktop {
    flex-direction: row;
  }
}

.radio {
  :global(.mantine-Radio-radio) {
    cursor: pointer;
    &:checked {
      background-color: $color-primary-500;
      border-color: $color-primary-500;
    }
  }
  :global(.mantine-Radio-label) {
    margin-top: 4px;
    cursor: pointer;
    @include label-l4;
  }
  :global(.mantine-Radio-icon) {
    top: 30%;
    left: 30%;
  }

  &__root:not(:first-child) {
    margin-top: 24px;
  }
}

.checkbox {
  :global(.mantine-Checkbox-body) {
    cursor: pointer;

    :global(.mantine-Checkbox-input) {
      cursor: pointer;
      &:checked {
        background-color: $color-primary-500;
      }
    }

    :global(.mantine-Checkbox-label) {
      margin-top: 4px;
      cursor: pointer;
      @include label-l4;
    }
  }
}

.textarea {
  :global(.mantine-Textarea-input) {
    min-height: 104px;
    border-radius: 12px;
    border: 1px solid $color-base-300;
    background-color: transparent;
  }

  &__more {
    margin-top: 20px;
  }
}

.image {
  img {
    margin-top: 20px;
    width: 100%;
    height: auto;

    @include desktop {
      width: 60%;
    }
  }
}

.input {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__network {
    @include desktop {
      width: 290px;
    }
  }

  &__label {
    @include label-l5;
    color: $color-base-700;
    margin-bottom: 4px;
  }
  &__input {
    @include label-l4-m;
    height: $gap-tablet;
    padding-left: 24px;
    border-radius: $other-input;
    background-color: $color-base-0;
    border: 1px solid $color-base-300;
    color: $color-base-900;
    outline: none;

    &::placeholder {
      color: $color-base-700;
    }

    &:active,
    &:hover,
    &:focus {
      border-color: $color-base-400;
      outline: none;
    }
  }
}

.bottom {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $color-base-0;
}

.butonnsGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  position: relative;
}

.buttonsCol {
  display: flex;
  flex-direction: column;
}

.button {
  &__root {
    height: 48px;
    width: 100%;
    border-radius: 12px;
    background-color: $color-base-100;
    color: $color-black;
    @include label-l4-m;
    outline: none;
    position: relative;

    &:hover {
      background-color: $color-base-200;
    }

    &:active {
      color: $color-base-0;
      background-color: $color-magenta-100;
    }

    &[data-disabled] {
      background-color: $color-base-200;
      color: $color-base-500;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__active {
    color: $color-base-0;
    background-color: $color-magenta-100;

    &:hover {
      background-color: $color-magenta-200;
    }
  }
}

.flipIcon {
  transform: rotate(180deg);
}

.agree {
  width: 100%;
  text-align: center;
  color: $color-black;
  @include label-l5;
  line-height: 14px;

  @include desktop {
    width: 42%;
    position: absolute;
    right: 20px;
  }

  a {
    color: $color-link;
  }
}

.or {
  @include paragraph-p5;
  color: $color-black;
}

.select {
  background-color: $color-base-0 !important;

  :global(.mantine-Select-input) {
    border-radius: $other-input;
    background-color: $color-base-0;
    border: 1px solid $color-base-300;
    color: $color-base-900;
    @include label-l4-m;
    outline: none;
    height: 48px;
  }

  :global(.mantine-Select-dropdown) {
    border-radius: $other-input;
    background-color: $color-base-0;
    border: 1px solid $color-base-300;
    color: $color-base-900;

    z-index: 999 !important;
  }
}

.instruction {
  display: flex;
  flex-direction: column;
  @include paragraph-p5;
  a {
    @include paragraph-p5;
  }
  strong {
    @include paragraph-p5;
    font-weight: 700;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__image {
    margin: 12px 0;
    width: 100%;
    height: auto;

    @include desktop {
      width: 335px;
    }
  }
}

.markdown {
  p {
    @include paragraph-p5;
    color: $color-black;
  }
  strong {
    @include paragraph-p5;
    font-weight: 700;
  }
  a {
    @include paragraph-p5;
    color: $color-magenta-100;
  }
}

.accordion {
  &__item {
    border: none;
    padding: 0;
    margin: 0;
    background-color: $color-base-0;

    &[data-active] {
      padding: 20px;
      background-color: $color-base-100 !important;
      margin-bottom: 20px;
    }
  }

  &__control {
    margin-bottom: 20px;
    margin-left: -20px;
    padding-left: 20px;
    @include label-l3;
    border-radius: 0 !important;
    background-color: $color-base-0 !important;
    display: flex;
    align-items: center;
    gap: 4px;

    @include desktop {
      height: 24px;
    }

    :global(.mantine-Accordion-chevron) {
      margin-top: 2px;
      margin-right: 140px;
    }

    :global(.mantine-Accordion-label) {
      padding: 0 !important;
    }

    &[data-active] {
      margin-bottom: 12px;
      background-color: $color-base-100 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      :global(.mantine-Accordion-chevron) {
        margin-top: 6px;
        margin-right: 100px;
      }
    }

    width: 100%;
    background-color: $color-base-0;
    color: $color-black;
    word-wrap: break-word;
    border: none;

    &[data-active] {
      background-color: $color-base-100;
    }

    &[data-active]:hover {
      background-color: $color-base-100;
    }

    &:hover {
      background-color: $color-base-100;
    }
  }

  &__chevron {
    color: $color-black;
    width: 20px;
  }

  &__content {
    @include label-l6;
    padding: 0;
    background-color: $color-base-100;
  }
}

/* .map {
  margin-top: 20px;
  width: 100%;
  height: 200px;
} */

.modal {
  &__root {
    z-index: 300;
    position: fixed;

    @include tablet {
      position: static;
    }
  }
  &__inner {
    padding: 0;
    width: 100%;
    height: 100dvh;
    border-radius: 0;
  }
  &__content {
    height: 100dvh;
    width: 100%;
    max-height: 100dvh;
    border-radius: 0;

    @include tablet {
      width: 478px;
      min-width: 478px;
      max-width: 478px;
      border-radius: $corner-radius-large;
      max-height: calc(100dvh - 100px);
      height: auto;
    }

    @include desktop {
      width: 640px;
      min-width: 640px;
      max-width: 640px;
    }
  }
  &__header {
    display: none;
  }
  &__body {
    padding: 0;
  }
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.scroll {
  @include scrollbar;
}

.loading {
  :global(.mantine-Overlay-root) {
    background-color: inherit;
  }
}

.bottomSheet {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottomSheetContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .title {
    @include heading-h4;

    @include tablet {
      @include heading-h2;
    }
  }

  .info {
    @include paragraph-p5;
    color: $color-base-900;

    @include tablet {
      @include paragraph-p4;
    }
  }
}
