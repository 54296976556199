@import 'global.scss';

.switch {
  &__thumb {
    width: 27px;
    height: 26px;
    border: none;
    transition: left 250ms ease;
  }
  &__input:checked + &__track &__thumb {
    left: 21.5px;
  }
  &__track {
    background-color: #bed0ff;
    width: 50px;
    min-width: 50px;
    height: 30px;
    border: none;
    transition: background-color 250ms ease;
  }
  &__input:checked + &__track {
    background-color: $color-magenta-100;
    transition: background-color 250ms ease;
  }
}
