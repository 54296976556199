@import 'global.scss';

.input {
  width: 100%;
  height: 48px;
  border-radius: $other-input;
  border: 1px solid $color-base-200;
  color: $color-base-900;
  @include label-l4-m;
  background-color: $color-base-100;

  &::placeholder {
    color: $color-base-700;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: 1px solid $color-base-300;
  }
}

.button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}
