@import 'media-queries';
@import '@/styles/global.scss';

.input {
  width: 100%;
  &__root {
    display: flex;
    width: 100%;
    @include only-mobile {
      padding: 8px 16px;
    }
  }

  &__dropdown {
    @include label-l4-m;
    line-height: 18px;
    max-width: 425px !important;
    left: 20px !important;
    position: absolute;
    z-index: $z-index-search-dropdown !important;
    background-color: $color-base-100;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 170, 0.15);
    @include desktop-max {
      left: 70px !important;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__itemsWrapper {
    background-color: $color-base-100;
  }

  &__input {
    @include label-l4-m;
    letter-spacing: -0.01em;
    background-color: $color-base-100;
    border: none;
    padding: 16px 24px;
    width: 100%;
  }
}

.closeIcon {
  color: $color-base-900;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdownItem {
  cursor: pointer;
  padding: 16px 0 16px 60px;
  &:first-child {
    &:hover {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &:hover {
    background-color: $color-base-300;
  }
}

.btnAll {
  @include label-l4-m;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 60px;
  color: $color-primary-500;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: $color-base-100;
  width: 100%;
  height: 62px;
  position: relative;
  align-items: center;
  flex-shrink: 0;

  &:hover {
    background-color: $color-base-300;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $color-base-400;
  }

  .arrow path {
    stroke: $color-primary-500;
  }
}

.scroll {
  @include scrollbar;

  &__viewport {
    padding-bottom: 0;
  }
}
