@import 'global.scss';

.root {
  &.tooltip {
    background-color: $color-base-900;
    padding: 12px 16px;
    display: block;
    @include paragraph-p5;
    border-radius: 8px;
    z-index: 10 !important;
    pointer-events: all;
  }

  &.light {
    background-color: $color-primary-500;
  }
}
