@import 'global.scss';

.wrapper {
  &__root {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    width: 100%;
  }

  &__label {
    @include label-l5;
    color: $color-base-700;
    margin-bottom: 4px;
  }

  &__error {
    @include label-l5;
    color: $color-error-fire;
  }
}

.input {
  &__wrapper {
    margin: 0;
    height: 100%;
  }

  &__input {
    @include label-l4-m;
    padding-left: 24px;
    border-radius: $other-input;
    background-color: $color-base-0;
    border: 1px solid $color-base-300;
    color: $color-base-900;
    outline: none;
    height: 100%;

    &::placeholder {
      color: $color-base-700;
    }

    &:active,
    &:hover,
    &:focus {
      border-color: $color-base-400;
      outline: none;
    }

    &[data-invalid] {
      border-color: $color-error-fire;
      color: $color-error-fire;
    }

    &.base {
      background-color: $color-base-100;
      border: none;
      padding: 20px 24px;
    }
  }

  &__right_section {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dark {
  .wrapper {
    &__label {
      color: $color-base-400;
    }
    &__error {
      color: $color-option-dance;
    }
  }

  .input {
    &__input {
      background-color: transparent;
      border-color: $color-base-200;
      color: $color-base-0;

      &::placeholder {
        color: $color-base-400;
      }

      &:active,
      &:hover,
      &:focus {
        border-color: $color-base-200;
      }

      &[data-invalid] {
        border-color: $color-option-dance;
        color: $color-option-dance;
      }

      &[data-disabled] {
        border-color: $color-base-200;
        background-color: transparent;
        opacity: inherit;

        &::placeholder {
          color: $color-base-400;
        }
      }
    }
  }
}
