@import 'global.scss';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 335px;
}

.root {
  border-radius: 12px;
  height: $gap-mobile;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover {
    background-color: $color-base-100;
  }

  &.selected {
    background-color: $color-primary-500;

    .label {
      color: $color-base-0;
    }
  }

  &.disabled {
    pointer-events: none;

    .label {
      color: $color-base-400;
    }
  }
}

.inner {
  display: none;
}

.label {
  @include label-l4;
  color: $color-base-900;
  padding: 0;
  cursor: pointer;
}
