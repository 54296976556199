@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include tablet {
    gap: 16px;
  }
}

.title {
  @include heading-h4;
  margin: 0;

  @include desktop {
    @include heading-h2;
  }
}


.button {
  height: 48px;
  background-color: $color-magenta-100;
  border-radius: $other-input;
  @include label-l4-m;
  color: $color-base-0;
  border: none !important;
  outline: none !important;

  &:active, &:hover {
    background-color: $color-magenta-200;
  }

  @include desktop {
    width: 290px;
  }
}

.bottomsheet {
  position: relative;
  z-index: 3000;

  &[data-rsbs-root='true'] [data-rsbs-overlay] {
    border-radius: 24px 24px 0 0;
  }

  &[data-rsbs-root='true'] [data-rsbs-scroll] {
    margin-top: -20px;
  }

  &[data-rsbs-root='true'] [data-rsbs-header] {
    border-radius: 24px 24px 0 0;

    &::before {
      background-color: $color-base-700;
    }
  }
}

.modal {
  &__content {
    max-width: 478px;
    min-width: 478px;
    width: 478px;
    overflow: hidden;
    box-shadow: 0 25px 45px 0 rgba(52, 86, 251, 0.2);

    @include desktop {
      max-width: 640px;
      min-width: 640px;
      width: 640px;
    }
  }

  &__header {
    position: absolute;
    right: 24px;
    top: 32px;
    padding: 0;
    background-color: transparent;
  }
  
  &__body {
    padding: 0;
  }

  &__close {
    color: $color-base-800;
    background-color: transparent;
    border: none !important;
    outline: none !important;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}