html,
body {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

a {
  color: #000000;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

h1 {
  margin: 0;
  padding: 0;
}
