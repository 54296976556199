@import 'global.scss';

.portal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-alert;
  padding-bottom: 20px;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &__none {
    display: none;
  }
}


.content {
  width: 170px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-base-900;
  border-radius: 12px;
  color: $color-base-100;
  @include paragraph-p5;
  animation: show 3000ms ease-in-out;
}

@keyframes show {
  from,
  to {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}

