@import 'global.scss';

$width: calc(100vw - 40px);

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 40px);
  margin-left: -20px;
  background: $color-base-100;
  max-height: 272px;
  overflow: hidden;

  @include tablet {
    border-radius: $corner-radius-large;
    overflow: hidden;
    margin-left: 0;
    width: 100%;
    max-height: 356px;
  }

  @include desktop {
    max-height: 414px;
  }
}

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: #dee2e6;
  animation: skeletonAnimation 1500ms linear infinite;
}

@keyframes skeletonAnimation {
  from,
  to {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }
}
