@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $corner-radius-large;
  padding: 0 24px 24px 24px;

  @include tablet {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  @include heading-h4;
  color: $color-black;

  @include desktop-max {
    @include heading-h2;
  }
}

.info {
  p {
    @include paragraph-p5;
    color: $color-black;

    @include desktop-max {
      @include paragraph-p4;
    }
  }
  span {
    font-weight: 500;
  }
}

.button {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  padding: 17px 32px;
  background-color: $color-magenta-100;
  color: $color-base-0;
  @include label-l4-m;
  outline: none;

  &:hover {
    background-color: $color-magenta-100;
  }
  &:active {
    background-color: $color-magenta-100;
  }
  &:focus,
  :focus-visible {
    outline: none;
  }
  &[data-disabled] {
    background-color: $color-primary-200;
    color: $color-base-500;
  }
  @include tablet {
    height: 40px;
    padding: 13px 24px;
  }
  @include desktop-max {
    width: 286px;
  }
  :global(.mantine-Button-label) {
    height: 48px;
  }
}

.bottomsheet {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  min-width: 478px;
  max-width: 478px;
  @include desktop-max {
    min-width: 640px;
    max-width: 640px;
  }
}

.closeButton {
  :global(.mantine-CloseButton-root) {
    outline: none !important;
  }
}
