@import 'global.scss';

.root {
  height: 40px;
  border-radius: 8px;
  padding: 1px;
  border: 1px solid $color-base-300;
  background-color: $color-base-0;
  width: 100%;

  @include tablet {
    width: 310px;
  }

  &__pills {
    height: 34px;
    background-color: transparent;
    border: none;
    padding: 0;
    
    @include tablet {
      width: auto;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  @include label-l3;
  color: $color-black;
  height: 100%;

  &[data-active] {
    color: $color-base-0 !important;
  }
  &__pills {
    padding: 10px 16px;
    @include label-l4;
  }
}

.indicator {
  top: 4px;
  left: 4px;
  border-radius: 7px;
  background-color: $color-primary-500;

  &__blue {
    border-radius: 8px;
  }
}
