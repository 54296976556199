@import 'global.scss';

.root {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include tablet {
    padding: 24px;
    padding-top: 0;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;

  span {
    @include heading-h5;
    color: $color-base-900;
    align-self: flex-start;
  }
}

.button {
  width: 100%;
  background-color: $color-magenta-100;
  color: $color-base-0;
  border-radius: 12px;
  @include label-l4-m;

  &:hover,
  &:focus {
    background-color: $color-magenta-200;
  }
  &:active {
    background-color: $color-magenta-0;
  }
}

.modal {
  &__content {
    max-width: 375px;
    width: 375px;
    border-radius: $corner-radius-large;
  }
  &__header {
    display: none;
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  &__body {
    padding: 0;
    padding-top: 40px !important;
  }
}

.scroll {
  @include scrollbar;
}
