@import 'typography';

@mixin markdown {
  h1 {
    @include heading-h1;
  }
  h2 {
    @include heading-h2;
  }
  h3 {
    @include heading-h3;
  }
  h4 {
    @include heading-h4;
  }
  h5 {
    @include heading-h5;
  }
  h6 {
    @include heading-h6;
  }
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  strong {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  u {
    text-decoration: underline;
  }
  code {
    display: inline-block;
    background-color: $color-base-600;
    border-radius: $other-input;
    color: $color-base-0;
    padding: 0 8px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style: none;
    li {
      @include paragraph-p5;
      position: relative;

      &::before {
        content: '·';
        font-size: 30px;
        position: absolute;
        left: -15px;
        line-height: 20px;
      }
    }
  }
  ol {
    margin: 10px 0;
    padding-left: 20px;
    li {
      @include paragraph-p5;
    }
  }

  blockquote {
    p {
      font-style: italic;
    }
  }
  em {
    font-style: italic;
  }
  a {
    @include label-l3-m;
    color: $color-primary-500;
    cursor: pointer;
    padding: 10px 0;
  }
  p,
  strong,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-base-900;
    margin: 10px 0;
  }
}

@mixin markdown-grey {
  @include markdown;
  p,
  strong,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-base-500;
  }
  a {
    color: $color-primary-200;
  }
}

@mixin markdown-white {
  @include markdown-grey;
  p,
  strong,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-base-0;
  }
}
