.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  background-color: unset;
  font-size: 14px;
  letter-spacing: -0.14px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.active::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #1b34c5;
  bottom: -1px;
  left: 0;
}
