@import 'global.scss';

.wrapper {
  background-color: $color-base-100;
  border: 1px solid $color-base-300;
  border-radius: $other-input;
}

.result {
  display: flex;
  align-items: center;
  color: $color-base-700;
  gap: 4px;
  padding: 4px 0;
}

.fileName {
  color: $color-primary-500;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.closeButton {
  cursor: pointer;
  display: flex;
}
