@import 'global.scss';

.container {
  height: 24px;
  width: 24px;
  background-color: $color-base-0;
  border: 1px solid $color-base-400;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__added {
    background-color: transparent;
    border: none;
  }
}
