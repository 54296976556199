@import 'global.scss';

.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
}

.title {
  @include heading-h4;
  color: #000;

  @include tablet {
    @include heading-h2;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  @include desktop {
    overflow: hidden;
    max-height: 28px;
  }
  @include only-mobile {
    align-content: center;
    gap: 16px;
    align-self: stretch;
  }
}

.link {
  color: $color-primary-500;
  @include paragraph-p4;
}

.searchInputWrapper {
  width: 100%;
}
