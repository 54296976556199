@import 'global.scss';

.card {
  margin: 0 -20px;
  padding: 20px;
  height: 260px;
  width: calc(100% + 40px);
  color: $color-base-0;
  border-radius: 0;
  background-image: url('/assets/img/footer/pc_mobile.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: app;
  gap: 24px;

  @include tablet {
    margin: 0;
    background-image: url('/assets/img/footer/pc_tablet.png');
    height: 204px;
    width: calc(100% + 80px);
    border-radius: 24px;
  }

  @include desktop {
    background-image: url('/assets/img/footer/pc_desktop.png');
  }

  &__textblock {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet {
      gap: 8px;
    }
  }

  svg {
    position: absolute;
  }

  &__info {
    @include short-text-t5;
    display: flex;
    flex-direction: column;
  }

  &__faqPage {
    padding: 20px;
    width: 100%;
    height: 96px;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/assets/img/footer/pc_desktop_faq.png');
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $color-base-0;
    position: relative;

    &__phone {
      width: 333px;
      height: 195px;
      position: absolute;
      bottom: 0;
      left: -60px;
    }

    &__title {
      @include heading-h4;
    }

    &__textbloc {
      margin-top: 0;
      margin-left: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.title {
  display: flex;
  flex-direction: column;

  &__firstWord {
    @include heading-h3;
    @include tablet {
      @include heading-h4;
    }
  }

  &__secondWord {
    @include heading-h3;
    @include tablet {
      @include heading-h4;
    }
    @include desktop {
      @include heading-h3;
    }
  }
}

.button {
  margin-bottom: 6px;
  min-height: 40px;
  width: 150px;
  border-radius: 12px;
  background-color: $color-base-0;
  color: $color-black;
  @include label-l4-m;
  outline: none;

  @include tablet {
    margin-top: auto;
  }

  &:hover {
    background-color: $color-base-200;
  }
  &:active {
    background-color: $color-base-300;
  }

  &__faqPage {
    margin: 0;
  }
}
