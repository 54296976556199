@import 'global.scss';

@mixin disabled {
  &[data-disabled] {
    background-color: $color-base-200 !important;
    .label {
      color: $color-base-500 !important;
    }
  }
}

.root {
  background-color: $color-primary-500;
  border-radius: $other-input !important;
  @include disabled;
  width: 100%;

  &:hover {
    background-color: $color-primary-400;
  }

  &:active {
    background-color: $color-primary-600;
  }

  &[data-loading="true"] {
    background-color: $color-primary-600;
    cursor: progress;
  }

  &.size {
    &__m {
      height: 48px;
    }
    &__s {
      height: 40px;
    }
    &__xs {
      height: 32px;
    }
  }

  .label {
    @include label-l4-m;
    color: $color-base-0;

    &__small {
      @include label-l5-m;
    }
  }

  &.magenta {
    background-color: $color-magenta-100;
    @include disabled;

    &:hover, &:active, &[data-loading='true'] {
      background-color: $color-magenta-200;
    }
  }

  &.base {
    background-color: $color-base-100;
    @include disabled;

    &:hover {
      background-color: $color-base-200;
    }

    &:active {
      background-color: $color-base-300;
    }

    .label {
      color: $color-primary-500;
    }
  }
}
