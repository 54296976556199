@import 'global.scss';

html {
  scroll-padding-top: 80px; /* высота фиксированного блока */
  scroll-behavior: smooth;

  @include only-mobile {
    scroll-padding-top: 48px;
  }
}
