@import 'global.scss';

.content {
  position: relative;
  padding: 24px !important;
  display: flex;
  flex-direction: column;
  gap: $corner-radius-large;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: calc(100vh - 100px);

  &::before {
    @include pink-bg-circle;
    right: -57px;
    top: -125px;
    pointer-events: none;
  }

  &::after {
    @include purple-bg-circle;
    left: -97px;
    top: 240px;
    z-index: -1;
    pointer-events: none;
  }
}

.withoutCircle {
  &::before {
    display: none;
  }

  &::after {
    display: none;
  }
}

.header {
  background: transparent;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: block;
  margin: 0;
  padding: 0;
}

.body {
  padding: 0;
  width: 100%;
}

.close {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: block;
  margin: 0;
  padding: 0;
  --cb-icon-size: 100%;
  --_cb-color: #414961 !important;
  background-size: cover;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    background: transparent;
  }
}
