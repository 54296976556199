@import 'global.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
}

.info {
  @include label-l6;
  color: $color-base-700;
}

.icon {
  width: 16px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

