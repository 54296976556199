@import 'global.scss';

.card {
  padding: 20px;
  margin: 0 -20px;
  width: calc(100% + 40px);
  height: fit-content;
  gap: 16px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-base-100;
  cursor: pointer;

  @include tablet {
    margin: 0 20px 0 0;
    padding: 24px 20px;
    height: 182px;
    border-radius: 24px;
    width: 100%;
  }

  @include desktop {
    margin-right: $corner-radius-large;
    width: auto;
    height: 204px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    margin: 0;
    @include manrope-m1;
    color: $color-black;

    @include tablet {
      @include heading-h4;
      color: $color-neutral-gray;
    }

    @include desktop {
      @include heading-h3;
    }
  }

  p {
    margin: 0;
    @include short-text-t5;
    color: $color-black;
  }
}

.button {
  margin-top: auto;
  min-height: 40px;
  width: 100%;
  border-radius: 12px;
  background-color: $color-link;
  color: $color-base-0;
  @include label-l4-m;
  outline: none;

  @include tablet {
    width: 94px;
  }
  @include desktop {
    width: 100%;
  }

  &:hover {
    background-color: $color-primary-400;
  }
  &:active {
    background-color: $color-link;
  }
  &[data-disabled] {
    background-color: $color-primary-200;
    color: $color-base-500;
  }
}
