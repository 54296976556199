@import 'global.scss';

.wrapper {
  margin: $gap-mobile 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;

  @include tablet {
    margin-top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    align-items: center;
    height: 314px;
  }

  @include desktop {
    height: 504px;
  }
}

.leftBlock {
  width: 100%;
  display: flex;

  @include only-mobile {
    margin-bottom: $gap-tablet;
  }

  @include desktop {
    width: 50%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: auto;

  @include tablet {
    width: 80%;
  }
  @include desktop {
    width: 70%;
  }

  .title {
    @include heading-h4;
    color: $color-base-900;

    @include tablet {
      @include heading-h2;
    }
  }

  .text {
    @include heading-h6;
    color: $color-base-700;
  }
}

.button {
  margin-top: $corner-radius-small;
  width: 100%;
  min-height: 48px;
  background: $color-primary-500;
  border-radius: $other-input;
  @include label-l4-m;

  @include tablet {
    width: fit-content;
  }
}

.image {
  margin: auto;
  width: 228px;
  height: auto;

  @include tablet-only {
    display: none;
  }

  @include desktop {
    margin: 0;
    width: 310px;
    height: 280px;
  }
}
