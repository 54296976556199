@import 'global.scss';

.container {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  gap: $gap-mobile;
  height: 136px;
  border-radius: $corner-radius-small;
  border: 1px solid $color-base-300;
  background-color: $color-base-0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  @include label-l3-brand;
  color: $color-volna-blue;
  display: block;
  height: 24px;
  display: flex;
  align-items: end;
}

.info {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.root {
  height: 68px;
}

.track {
  background-color: $color-base-300;
  height: 4px;
  margin: 0 14px;

  &::before {
    background-color: $color-base-300;
    right: -14px;
    left: -14px;
  }
  &__container {
    height: 28px;
  }
}

.bar {
  background-color: $color-volna-blue;
  left: -14px;
}

.mark {
  display: block;
  position: absolute;
  top: -27px;
  width: 10px;
  height: 10px;
  border: none;
  background-color: $color-base-300;

  &__container {
    width: calc(100% - 10px);
    left: 4.5px;
  }
  &__filled {
    background-color: $color-volna-blue;
  }
  &__label {
    @include label-l4;
    color: $color-base-900;
    &:first-of-type {
      position: absolute !important;
      left: 28px !important;
    }
  }
  &__wrapper {
    top: 36px;
  }
}

.thumb {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  box-shadow: 0px 4px 8px 0px rgba(40, 45, 60, 0.2);
  border: none;
  background-color: $color-volna-blue;
}

.label {
  display: none;
}

.pink {
  .text {
    color: $color-magenta-100;
  }
  .bar {
    background-color: $color-magenta-100;
  }
  .mark {
    &__filled {
      background-color: $color-magenta-100;
    }
  }
  .thumb {
    background-color: $color-magenta-100;
  }
}
