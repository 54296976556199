@import 'media-queries';

.wrapper {
  width: 100%;
  height: 44px;
  display: flex;
  background-color: $color-base-0;
  justify-content: space-between;
  align-items: flex-start;

  @include tablet {
    height: 80px;
    align-items: center;
  }
}

.navigation {
  display: none;

  @include tablet {
    padding-top: 14px;
    margin-left: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    color: $color-black;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 14px;
    font-style: normal;
    letter-spacing: -0.16px;
  }
}

.navigationHiden {
  visibility: hidden;
}

.button {
  padding: 10px;
  border-radius: 8px;
  &:hover {
    background-color: $color-light-grey;
  }

  &__activeLink {
    color: $color-primary-500;
  }
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 32px;

  @include desktop-max {
    align-items: center;
  }
}

.personal {
  display: none;

  @include desktop-max {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    gap: 10px;
    color: $color-primary-500;
    background-color: $color-base-200;
    border-radius: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 14px;
    white-space: nowrap;

    &:hover {
      background-color: $color-base-300;
    }

    &__label {
      margin-top: 2px;
    }
  }
}

.search {
  cursor: pointer;
  flex-grow: 2;
  width: 100%;
  @include tablet {
    display: inline-flex;
  }
}

.searchInputWrapper {
  width: 100%;
}

.searchOpen {
  flex-grow: 2;
  width: 100%;
}

.user {
  display: inline-flex;
  position: absolute;
  padding: 0px 20px 10px 0px;
  right: 0;
  bottom: 0;

  @include tablet {
    position: initial;
    padding-top: 6px;
    padding-right: 0;
    padding-bottom: 0;
  }

  @include desktop-max {
    display: none;
  }
}

.logo {
  display: inline-flex;
  position: absolute;
  @include only-mobile {
    padding-top: 6px;
  }
}
