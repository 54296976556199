@import 'global.scss';

.main-page {
  .tariffs {
    .swiper-slide {
      margin-right: 20px !important;
    }
  }
  .pills-tariff {
    background-color: $color-base-0 !important;
    @include tabs-animation;
    :global(.mantine-SegmentedControl-input) {
      opacity: 0 !important;
    }
  }
  .replenishment {
    @include tabs-animation;
  }
  .esim-btn,
  .sale-btn {
    height: 48px;
    border-radius: 12px;
    font-size: 14px;
    border: none;
    font-weight: 500;
    line-height: 14px;
  }
}

@include only-mobile {
  .burger {
    @include tabs-animation;
  }
}

.tariff-slug-page {
  @include tabs-animation;
  .header {
    z-index: $z-index-slider-controls !important;

    .title {
      @include heading-h4;
      color: $color-primary-900;

      @include tablet {
        @include heading-h2;
      }
    }
  }

  .tab {
    border: none !important;
    border-bottom: 1px solid $color-base-400 !important;
    color: $color-base-700 !important;
    background-color: transparent !important;
    z-index: $z-index-slider-controls !important;

    &[data-active] {
      border-bottom: 3px solid $color-primary-500 !important;
    }
  }
}

.building-tabs {
  border: 1px solid $color-base-300 !important;
  background-color: $color-base-0 !important;
  @include tabs-animation;
}

.mob-app-tab {
  @include tabs-animation;
}