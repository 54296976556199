@import 'global.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__disabled {
    @include disabled-block;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  gap: $gap-mobile;
  border-radius: $corner-radius-small;
  background-color: $color-base-100;
}

.title {
  @include headings;
}

.slider {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $gap-mobile;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    @include heading-h4;
    color: $color-base-900;
  }
}

.button {
  &__root {
    width: 100%;
    height: 48px;
    background-color: $color-magenta-100;
    color: $color-base-0;
    height: 48px;
    @include label-l4-m;
    padding: 0;
    margin: 0;
    border-radius: $other-input;
    border: none;

    &:active,
    &:focus {
      background-color: $color-magenta-100;
      border: none;
      outline: none;
    }

    &:hover {
      background-color: $color-magenta-200;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  @include tablet {
    &__root {
      width: 290px !important;
    }
  }
}

.marks {
  &__first {
    position: absolute;
    left: 0;
  }

  &__last {
    position: absolute;
    right: 0;
  }
}
