@import 'global.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $gap-horizontal;
  position: relative;
}

.title {
  @include headings;
}

.swiper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% + 20px);

  @include desktop-max {
    width: 100%;
    position: static !important;
  }

  :global(.swiper-wrapper) {
    display: flex;
    align-items: center;
  }

  :global(.swiper-button-next) {
    transform: translateY(-50%);
    top: calc((100% + 74px) / 2) !important;
    @include swiper-button-offset-right;
  }

  :global(.swiper-button-prev) {
    transform: translateY(-50%);
    top: calc((100% + 74px) / 2) !important;
    @include swiper-button-offset-left;
  }

  :global(.swiper-slide) {
    width: 310px !important;
    border-radius: $corner-radius-large;
    overflow: hidden;
  }

  :global(.swiper-pagination) {
    height: 18px !important;
    align-items: flex-end !important;
  }
}

