@import 'global.scss';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  margin-bottom: 4px;
  @include label-l5;
  color: $color-base-700;
}

.wrapper {
  margin: 0;
}

.input {
  padding-left: 16px;
  border-radius: $other-input;
  background-color: $color-base-100;
  color: $color-base-900;
  @include label-l4-m;
  border: none;
  outline: none;
  height: 48px;

  &::placeholder {
    color: $color-base-700;
  }
}

.right_section {
  display: block;
  margin: auto 20px;
  height: 20px;
  width: 20px;
  pointer-events: none;
}

.error {
  @include label-l5;
  color: $color-error-fire;
}

.dropdown {
  background-color: $color-base-100;
  border-radius: $other-input;
  margin-top: 4px;
  border: none;

  &__items_wrapper {
    padding: 0;
  }
  &__item {
    border-radius: 0;
    padding: 16px 12px;
    height: 46px;
    display: flex;
    align-items: center;
    @include label-l4-m;
    color: $color-base-900;

    &:first-of-type {
      border-radius: $other-input $other-input 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 $other-input $other-input;
    }

    &:hover {
      background-color: $color-base-300;
    }
    &:active {
      color: $color-primary-500;
    }

    &[data-selected] {
      background-color: $color-base-100;
      color: $color-primary-500;

      &:hover {
        background-color: $color-base-300;
        color: $color-primary-500;
      }
    }
  }
}

.light {
  .input {
    background-color: $color-base-0;
    border: 1px solid $color-base-200;

    &:hover,
    &:focus,
    &:active {
      border-color: $color-base-400;
    }
  }

  .dropdown {
    background-color: $color-base-0;
    border: 1px solid $color-base-400;

    &__item {
      &[data-selected] {
        background-color: $color-base-0;
      }
    }
  }
}

.disabled {
  .label {
    color: $color-base-400;
  }

  .input {
    background-color: $color-base-100 !important;
    color: $color-base-400 !important;

    &::placeholder {
      color: $color-base-400 !important;
    }
  }

  .right_section {
    display: block !important;
  }
}
