@import 'global.scss';

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 68px;
  background-color: $color-base-100;
  overflow-x: clip;

  @include tablet {
    padding-bottom: 0;
  }
}

.centralColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $breakpoint-max;

  padding: 0 20px;
  background-color: $color-base-0;
  width: 100%;

  @include desktop-max {
    padding: 0 70px 0;
    min-height: 100vh;
  }
}

.header {
  position: sticky;
  top: -1px;
  left: -1px;
  right: 0;
  z-index: 200;
  width: calc(100% + 42px);
  padding-left: 20px;
  padding-right: 20px;
  background-color: $color-base-0;
  transition-duration: 2s;
  transition-property: z-index;
  margin: -1px -1px 0;

  &:hover {
    z-index: $z-index-header-hover;
  }

  @include tablet {
    width: calc(100% + 40px);
    padding-left: 64px;
  }

  @include desktop-max {
    padding-left: 70px;
    padding-right: 70px;
    width: $breakpoint-max;
  }
}

.headerSidebarOpened {
  z-index: $z-index-header-sidebar-opened;
  transition: none;

  &:hover {
    z-index: $z-index-header-sidebar-opened;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //z-index: 3;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  z-index: $z-index-footer-wrapper;

  @include tablet {
    gap: $gap-tablet;
  }

  @include desktop-max {
    gap: $gap-desktop;
  }
}
