@import '@/styles/media-queries';
@import 'global.scss';

.wrapper {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  background-color: $color-base-0;
  justify-content: space-between;
  align-items: flex-start;

  &__tariff {
    margin-bottom: 70px;
  }

  @include tablet {
    height: 84px;
    padding-left: 0;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    position: relative;


    &::before {
      content: '';
      width: calc(100% + 40px);
      height: 1px;
      background-color: #E5E8EB;
      position: absolute;
      top: 0;
      left: -20px;
    }

    &__tariff {
      margin-bottom: 104px;
    }
  }

  @include desktop-max {
    margin-bottom: 16px;

    &::before {
      width: 100%;
      left: 0;
    }
  }
}

.leftSide {
  display: flex;
  gap: 12px;
  align-items: center;

  @include only-mobile {
    display: none;
  }
}

.logo {
  display: inline-flex;
}

.tagline {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yourConnection {
  color: $color-base-900;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.yourCrimea {
  color: $color-base-900;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.telecom2023 {
  min-width: fit-content;
  color: $color-base-500;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @include only-mobile {
    display: flex;
    word-wrap: break-word;
  }
}

.supportService {
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  @include only-mobile {
    margin-top: 24px;
  }
}

.support {
  @include label-l4;
  color: $color-base-700;
  margin-right: 8px;

  @include desktop {
    @include label-l3;
  }
}

.phoneNumber {
  @include label-l3;
  color: $color-primary-500;
  font-weight: 500;
  cursor: pointer;
  @include desktop {
    @include label-l2;
    font-weight: 500;
  }
}
