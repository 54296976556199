@import 'media-queries';

.wrapper {
  display: flex;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  letter-spacing: -0.16px;
  transition: 0.2s background-color ease-in;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  flex-direction: column;
  // stroke: #2f384c;

  @include tablet {
    width: 64px;
  }

  @include desktop-max {
    width: 70px;
  }
}

.firstIcon {
  stroke: #2f384c;
}

.hiddenContent {
  width: 0;
  height: 0;
  opacity: 0;
  transition: width height 0.2s linear;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.visibalityContent {
  width: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.withArrow {
  display: flex;
  gap: 35px;
}

.active::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: $color-primary-500;
}

.innerLabel {
  margin-top: 4px;
  font-size: 11px;
  font-weight: 400;
  color: #515b73;
}

.iconActiveColor {
  color: $color-primary-500;
}

.innerLabelActive {
  color: $color-primary-500;
}
