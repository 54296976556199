@import 'global.scss';

.status {
  height: $corner-radius-large;
  width: $corner-radius-large;
  border-radius: $corner-radius-large;
  background-color: $color-magenta-100;
  color: $color-base-0;
  @include label-l4;
  display: flex;
  justify-content: center;
  align-items: center;

  &__disabled {
    background-color: $color-base-500;
  }
}

.item {
  &__disabled {
    opacity: 50%;
    @include label-l4;
  }
}